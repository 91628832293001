<template>
    <vs-popup style="color:rgb(255,255,255)" fullscreen background-color-popup="rgb(40,61,85)" title="Объединение сюжетов"
        :active.sync="popupVisible" @close="isClosed()">
        <div>

            <vs-table ref="popupGrid" :data="filtered_bunches_list"
                style="color:rgb(255,255,255);background-color:rgb(40,61,85);" thead-class="hidden_header">
                <template #header>
                    <h4>Выбранные сюжеты</h4>
                </template>
                <template #thead>
                    <vs-th>
                    </vs-th>
                    <vs-th>
                        Номер сюжета
                    </vs-th>
                    <vs-th>
                        Текст сюжета
                    </vs-th>
                </template>
                <template slot-scope="{data}">


                    <vs-tr v-for="(tr, indextr) in data" :key="indextr" :is-selected="!!selectedCandidates.includes(tr)"
                        @click="getHistory(tr.bunch_id)">
                        <vs-td>
                            <vs-checkbox :vs-value="tr" v-model="selectedCandidates" />
                        </vs-td>
                        <vs-td>
                            {{ tr.bunch_id }}
                        </vs-td>
                        <vs-td>
                            {{ tr.bunch_auto_summary }}
                        </vs-td>
                    </vs-tr>
                </template>
            </vs-table>
        </div>
        <div>
            <vs-table ref="popupGrid" :data="discovered_bunch_list"
                style="color:rgb(255,255,255);background-color:rgb(40,61,85);" thead-class="hidden_header">
                <template #header>
                    <h4>Подобранные сюжеты</h4>
                </template>
                <template #thead>
                    <vs-th>
                    </vs-th>
                    <vs-th>
                        Сюжет
                    </vs-th>
                    <vs-th>
                        Степень сходства
                    </vs-th>
                    <vs-th>
                        Сообщений в сюжете
                    </vs-th>
                </template>
                <template slot-scope="{data}">
                    <vs-tr v-for="(tr, indextr) in data" :key="indextr" :is-selected="!!selectedSuggestions.includes(tr)"
                        :data="tr">
                        <vs-td>
                            <vs-checkbox :vs-value="tr" v-model="selectedSuggestions" />
                        </vs-td>
                        <vs-td>
                            <a @click="getHistory(tr.bunch_id)">{{ tr.summary }}</a>
                        </vs-td>
                        <vs-td>
                            {{ tr.iou }}
                        </vs-td>
                        <vs-td>
                            {{ tr.count }}
                        </vs-td>
                    </vs-tr>
                </template>
            </vs-table>
            <h5>Потенциальные названия для нового сюжета</h5>
            <vs-li v-for="tr in titles_of_selections">{{ tr }}</vs-li>

            <p></p>
            <p></p>

            <!-- <b-col sm="2"> -->
            <label>Новое название для сюжета: </label>
            <input type="text" placeholder="Введите название для сюжета" v-model="new_title" />
            <!-- </b-col> -->


            <div class="center">
                <vs-button size="xl" @click="makeMerge()">
                    Тыдыщ
                </vs-button>
            </div>
        </div>
    </vs-popup>
</template>

<script>

import store from "@/store/store";

export default {
    name: "topic-modelling-bunch-feed-popup",
    components: {

    },
    emits: [
        'mergeCompleted',
    ],
    props: {
        popupVisible: Boolean,
        bunches_list: Array,
        stream: String,
        currentSelections: Array,
    },
    data() {
        return {
            // : false, // put outside of component
            // filtered_bunches_list: [],
            selectedCandidates: [],
            discovered_bunch_list: [],
            selectedSuggestions: [],
            titles_of_selections: [],
            new_title: '',
        };
    },
    mounted() {

    },
    methods: {
        isClosed() {
            console.log('dialog is closed');
            this.$emit('mergeCompleted');
        },
        makeMerge() {
            console.log(this.bunch_ids_to_merge);
            this.axios({
                method: 'post',
                url: this.getHostname() + "/plot-modelling/merge-bunches",
                data: {
                    'bunch_ids': this.bunch_ids_to_merge,
                    'title': this.new_title,
                    'user_id': store.state.user_id
                },
            }).then((res) => {
                this.discovered_bunch_list = res.data.hints.filter((x) => x.iou > 0.01);
                console.log(this.discovered_bunch_list);
                this.$vs.loading.close();
            });

            //TODO: finalize the logic
            this.$emit('mergeCompleted');

        },
        mergeClicked() {
            this.selectedCandidates = this.filtered_bunches_list.slice()
            this.$vs.loading();
            this.axios({
                method: 'post',
                url: this.getHostname() + "/plot-modelling/get-neighbor-hints",
                data: { 'bunch_ids': this.currentSelections },
            }).then((res) => {
                this.discovered_bunch_list = res.data.hints.filter((x) => x.iou > 0.01);
                console.log(this.discovered_bunch_list);
                this.$vs.loading.close();
            });
        },
        getHostname() {
            let basic_url = ''
            if (this.stream == "SberBA") {
                basic_url = basic_url + this.$hostname_ba
            } else {
                basic_url = basic_url + this.$hostname_ba_macro
            }
            return basic_url;
        },


        getTitles() {
            this.axios({
                method: 'post',
                url: this.getHostname() + "/plot-modelling/get-titles-of-bunches",
                data: { 'bunch_ids': this.bunch_ids_to_merge },
            }).then((res) => {
                console.log(res);
                this.titles_of_selections = res.data.titles;
                if (this.titles_of_selections.length > 0) {
                    this.new_title = this.titles_of_selections[0]
                };
            });
        },

    },
    computed: {
        filtered_bunches_list() {
            return this.bunches_list.filter((r) => this.currentSelections.includes(r.bunch_id));
        },
        bunch_ids_to_merge() {
            return this.selectedSuggestions.concat(this.selectedCandidates).map((x) => x.bunch_id);
        },


    },
    watch: {
        bunch_ids_to_merge() {
            this.getTitles();
        }
    }

};


</script>


<style>
.vs-th {
    background: rgb(31 45 61);
}

.vs-table--tbody-table tr,
th {
    background: rgb(31 45 61);

}

.vs-con-table table {
    background: rgb(31 45 61);
}
</style>