<template>
    <h5 :style="styleObject" @click="btnClicked()" @mouseover="mo_event(true)" @mouseleave="mo_event(false)"
        :class="getclass()">{{
            displayedText
        }}</h5>
</template>

<script>
export default {
    props: ['neText', 'neId', 'stream'],
    emits: ['btnClicked'],
    data() {
        return {
            button: {
                colorBackd: '#921b49',
                colorBackdHover: 'white',
                text: 'Results',
                color: 'white',
                colorHover: "#921b49",
                borderColor: '#d3e0ff',
                borderColorHover: "#E02629"
            },
            upHere: false,
        }

    },
    methods: {
        getclass() {
            let st = "border tagbutton border-primary badge tagbuttonact";
            return st;


        },
        mo_event(status) {

            this.upHere = status;

        },
        getHostname(stream) {
            let basic_url = ''
            if (stream == "SberBA") {
                basic_url = basic_url + this.$hostname_ba
            } else {
                basic_url = basic_url + this.$hostname_ba_macro
            }
            return basic_url;
        },

        btnClicked() {
            let url = '' + '/topic-modelling-live/nerHistory/' + this.neId + '/' + this.stream;
            window.open(url, '_blank');
            $emit('btnClicked');
        },

        btnClickedInternal() {
            console.log('btn clicked');
        },



    },
    computed: {
        displayedText() {
            return this.neText;
        },

        styleObject() {
            return {
                '--button-color': this.button.color,
                '--button-background-color': this.button.colorBackd,
                '--button-border-color': this.button.borderColor,

                '--button-color--hover': this.button.colorHover,
                '--button-background-color--hover': this.button.colorBackdHover,
                '--button-border-color': this.button.borderColorHover
            };
        },
    },
}
</script>

<style>
.tagbutton {
    color: var(--button-color);
    background-color: var(--button-background-color);
    border-color: var(--button-border-color);
}

.tagbuttonact:hover {
    color: var(--button-color--hover);
    background-color: var(--button-background-color--hover);
    border-color: var(--button-border-color--hover);
}
</style>


