<template>
    <div class="cut-msg">
        <div>
            <h3> Именованные сущности</h3>
            <ner-button v-for="value in ners" :neText="value.text" :neId="value.id" :stream="stream"
                :key="value.id"></ner-button>
        </div>

        <h3> Релевантные сообщения</h3>
        <table class="table mb-0 table-center text-secondary" style="font-size:small; width:100%; table-layout:fixed;"
            rules="groups">
            <colgroup>
                <col span="1" style="width:90%;">
                <col span="1" style="width:5%;">
            </colgroup>
            <tbody>
                <template v-for="(m, m_id) in messages">
                    <tr style="background-color: #1f2d3d !important; color: #9cb2da !important;">
                        <td style="word-wrap: break-word">{{ m.text }}</td>
                        <td>
                            <a style="pointer-events: auto;" :href=m.url target="_blank">ссылка</a>
                        </td>
                    </tr>
                </template>
            </tbody>
        </table>
    </div>
</template>


<script>
import NerButton from "./nerButton.vue";
export default {
    props: ["messages", "ners", "stream"],
    components: {
        NerButton
    },
    data() {
        return {
        };
    },
}
</script>

<style>
div.cut-msg {
    color: #9cb2da
}

.span {
    display: block
}
</style>
