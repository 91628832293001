<template>
  <div>
    <div class="container">
      <div class="row m-12 justify-content-center align-items-center  vs-con-loading__container" id="div-with-loading">
        <div class="col-12 align-content-center justify-content-center">
          <h3>Лента сюжетов
            <span>
              <a href="javascript:void(0)" v-b-modal.HelpForm data-toggle="modal" data-target="#HelpForm"
                style="border: none;" class="text-primary">
                <help-circle-icon class="fea icon-sm"></help-circle-icon>
              </a>
            </span>
            <b-modal id="HelpForm" title="Справка по сервису" centered button-size="sm" size="lg" ok-only>
              <div class="bg-white p-1 rounded box-shadow">
                <div class="text-muted mb-2" style="font-size: small;">
                  <p>
                    Сейчас Вы видите главную страницу ленты сюжетов, на ней показаны сюжеты и дополнительная информация
                    к ним.
                  </p>

                  <p>
                    Для получения сюжетов мы берем поток сообщений за определенный период (указан в шапке).
                    Эти сообщения обрабатываются и кластеризуются (т.е. разбиваются на некоторые группы).
                    К каждой группе мы автоматически подбираем название (это второй столбец в таблице), считаем
                    количество сообщений в нем с дублями и без (3 и 4 столбцы)
                  </p>

                  <p>
                    Табличку можно сортировать по числовым столбцам.
                  </p>

                  <p>
                    При нажатии на сюжет можно посмотреть наиболее интересные сообщений к нему. Отбор сообщений
                    производится по интегральной метрике связывающей сообщения с наибольшим охватом, количеством лайков
                    и тп.
                    Мы берем несколько таких показателей и строим на них некоторую интегральную метрику, и самые
                    интересные сообщения по этой метрике мы показываем. Рядом с каждым сообщением мы показываем ссылку
                    на источник.
                  </p>

                  <p>
                    Также для каждого сообщения показываются ключевые слова, посчитанные на всех сообщения сюжета - это
                    слова наиболее важные по разным метрикам для описания именно этого сюжета.
                  </p>

                  <p>
                    На данный момент имеется два потока данных. Один по сберу (в сообщениях обязательно должно
                    встретится ключевое слово из нашей экосистемы), второй - это общий поток.
                  </p>

                  <p>
                    Табличку можно скачать нажав на кнопку скачивания.
                  </p>

                  <p>
                    Создание кластеров мы проводим каждые полчаса или час в зависимости от потока.
                  </p>

                  <p>
                    Когда кластеризация готова, в последующие полчаса или час мы каждую минуту к уже созданным сюжетам
                    добавляем вновь пришедшие сообщения.
                    Время последней кластеризации и время последнего пришедшего сообщения показаны сверху.
                  </p>

                  <p>
                    По истечению получаса-часа мы считаем что информационная картина в мире могла поменяться, и могли
                    появиться новые сюжеты, и поэтому мы проводим сюжетирование заново - т.е. снова проводим
                    кластеризацию.
                    При такой процедуре после каждой кластеризации у нас может потеряться приемственность - ведь
                    создаются новые кластеры и мы не знаем как они связаны с предыдущими.
                    Поэтому у нас есть особый алгоритм связи кластеров, чтобы можно было смотреть историю развития
                    сюжетов.
                  </p>

                  <p>
                    Для того чтобы посмотреть историю сюжета нужно перейти по стрелочке вправо.
                  </p>
                </div>
              </div>
            </b-modal>
          </h3>
        </div>
        <div class="col-md-2 align-content-center justify-content-center">
          <h5>
            Источник
          </h5>
          <div class="align-content-center justify-content-center">
            <div class="custom-control custom-radio custom-control-inline">
              <div class="form-group mb-0">
                <input type="radio" id="customRadio1" name="customRadio" checked @change="onChange(true)"
                  class="custom-control-input" />
                <label class="custom-control-label" for="customRadio1">Сбербанк</label>
              </div>
            </div>
            <div class="custom-control custom-radio custom-control-inline">
              <div class="form-group mb-0">
                <input type="radio" id="customRadio2" name="customRadio" @change="onChange(false)"
                  class="custom-control-input" />
                <label class="custom-control-label" for="customRadio2">Макро</label>
              </div>
            </div>
          </div>
        </div>

        <div class="col-md-5 align-content-space-around justify-content-center text-info" style="font-size: small;">
          <div class="row md-3 ">
            <div class="col-sm">
              <filter-icon @click="filtersVisible ^= 1" v-b-tooltip.hover title="Фильтрация по тегам" />
            </div>
            <div class="col-sm">
              <git-merge-icon v-b-tooltip.hover title="Объединить выбранные сюжеты" @click="mergeClicked()" />
            </div>
            <div class="col-sm">
              <refresh-cw-icon v-b-tooltip.hover title="Обновить ленту" @click="getData()" />
            </div>
            <div class="col-sm">
              <eye-off-icon v-b-tooltip.hover title="Очистить фильтры" @click="clearFilters()" />
            </div>
            <div class="col-sm">
              <download-cloud-icon v-b-tooltip.hover title="Скачать выгрузку" @click="getExcel()" />
            </div>
          </div>
        </div>

        <div class="col-md-3 align-content-right justify-content-right text-info" style="font-size: small;">
          <ul>
            <li>
              Частота сюжетирования:
              <span v-if="loading">
                ...
              </span>
              <span v-else>
                <span v-if="stream === 'SberBA'">
                  <b>1 час</b>
                </span>
                <span v-else>
                  <b>1 час</b>
                </span>
              </span>
            </li>
            <li>
              Окно сюжетирования:
              <span v-if="loading">
                ...
              </span>
              <span v-else>
                <span v-if="stream === 'SberBA'">
                  <b>24 часа</b>
                </span>
                <span v-else>
                  <b>24 часа</b>
                </span>
              </span>
            </li>
            <li>
              Последний расчет сюжетов:
              <span v-if="loading">
                ...
              </span>
              <span v-else>
                <b>{{ start_time }}</b>
              </span>
            </li>
            <li>
              Дата добавления последнего сообщения:
              <span v-if="loading">
                ...
              </span>
              <span v-else>
                <b>{{ last_message_time }}</b>
              </span>
            </li>
          </ul>
        </div>

        <div class="col-12 align-content-center justify-content-left text-info">
          <div v-if="filtersVisible">
            <b-form @submit.prevent="onSubmitTag">
              <b-form-select v-model="inputTagId" :options="knownTags" size="md"></b-form-select>
              <div class="row m-2 ">
                <b-button @click="includeClicked" variant="info" type="submit" size="md">Включить тег</b-button>
                <template v-for="(m, m_id) in incTags">
                  <tag-button :buttonText="m.text" :tagId="m.value" :isDeletable="true"
                    @btnClicked="removeTagFromList(m.value, 'include')">
                  </tag-button>
                </template>
              </div>
              <div class="row m-2 ">
                <b-button @click="excludeClicked" variant="info" type="submit" size="md">Исключить тег</b-button>
                <template v-for="(m, m_id) in excTags">
                  <tag-button :buttonText="m.text" :tagId="m.value" :isDeletable="true"
                    @btnClicked="removeTagFromList(m.value, 'exclude')">
                  </tag-button>
                </template>
              </div>
            </b-form>
          </div>
        </div>
      </div>


      <merge-popup-feed ref="popup" :popupVisible="popupVisible" :bunches_list="bunches_list" :stream="stream"
        :currentSelections="currentSelections" @mergeCompleted="finalizeMerge()" />
      <div>
        <div>
          <div v-if="~sorted_bunches_list & loading">
            Loading....
          </div>
          <div v-else class="table-responsive bg-white shadow rounded">
            <table class="table nocolor mb-0 table-center" style="table-layout:fixed;">
              <thead>
                <tr>
                  <th style="width:2%;">
                  </th>
                  <th scope="col" style="width:5%; text-align:center; cursor: pointer;" @click="sort('bunch_id')">
                    ID Сюжета
                    <span v-if="currentSort === 'bunch_id'">
                      <span v-if="currentSortDir === 'asc'">
                        <arrow-up-icon class="fea icon-sm"></arrow-up-icon>
                      </span>
                      <span v-else-if="currentSortDir === 'desc'">
                        <arrow-down-icon class="fea icon-sm"></arrow-down-icon>
                      </span>
                    </span>
                  </th>
                  <th scope="col" style="width:41%; text-align:center;">
                    Сюжет
                  </th>
                  <th scope="col" style="width:10%; text-align:center;">

                  </th>
                  <th scope="col" style="width:8%; text-align:center; cursor: pointer;" @click="sort('counts')">
                    Сообщений за 72 часа
                    <span v-if="currentSort === 'counts'">
                      <span v-if="currentSortDir === 'asc'">
                        <arrow-up-icon class="fea icon-sm"></arrow-up-icon>
                      </span>
                      <span v-else-if="currentSortDir === 'desc'">
                        <arrow-down-icon class="fea icon-sm"></arrow-down-icon>
                      </span>
                    </span>
                  </th>

                  <th scope="col" style="width:8%; text-align:center; cursor: pointer"
                    @click="sort('since_last_message_seconds')">
                    Последнее сообщение в сюжете
                    <span v-if="currentSort === 'newest_message_time'">
                      <span v-if="currentSortDir === 'asc'">
                        <arrow-up-icon class="fea icon-sm"></arrow-up-icon>
                      </span>
                      <span v-else-if="currentSortDir === 'desc'">
                        <arrow-down-icon class="fea icon-sm"></arrow-down-icon>
                      </span>
                    </span>
                  </th>

                  <th scope="col" style="width:8%; text-align:center; cursor: pointer"
                    @click="sort('cluster_lifetime_seconds')">
                    Время жизни сюжета
                    <span v-if="currentSort === 'cluster_lifetime'">
                      <span v-if="currentSortDir === 'asc'">
                        <arrow-up-icon class="fea icon-sm"></arrow-up-icon>
                      </span>
                      <span v-else-if="currentSortDir === 'desc'">
                        <arrow-down-icon class="fea icon-sm"></arrow-down-icon>
                      </span>
                    </span>
                  </th>
                  <th scope="col" style="width:3%; text-align:center;">История</th>
                </tr>
              </thead>
              <tbody>
                <template v-for="(x, id) in sorted_bunches_list">
                  <tr :class="{ opened: opened.includes(x.bunch_id) }">
                    <td><input type="checkbox" class="checkbox" v-model="x.selected" /></td>
                    <td style="text-align:center;">
                      {{ x.bunch_id }}
                    </td>
                    <td>
                      <p @click="toggle(x.bunch_id)">{{ x.bunch_title }}</p>
                      <div v-if="x.bunch_user_summary != ''" class="text-secondary" style="font-size: medium;">
                        {{ x.bunch_user_summary }}
                      </div>
                      <div class="text-secondary" style="font-size: small;">
                        <template v-for="kw in x.yake_keywords">
                          {{ kw }}; &nbsp;
                        </template>
                      </div>
                      <div>
                        <template v-for="(m, m_id) in x.tags">
                          <tag-button :buttonText="m.tag_name" :tagId="m.tag_id" :bunchId="x.bunch_id"
                            :isDeletable="false">
                          </tag-button>
                        </template>
                      </div>
                    </td>
                    <td style="text-align:center;">
                      <apexchart ref="theChart" type="donut" :options="chartOptions" :series="x.source_stats">
                      </apexchart>
                    </td>
                    <td style="text-align:center;">{{ x.counts }}</td>
                    <td style="text-align:center;">
                      {{ x.since_last_message }} назад
                    </td>
                    <td style="text-align:center;">
                      {{ x.cluster_lifetime }}
                    </td>
                    <td style="text-align:center;">
                      <a @click="getHistory(x.bunch_id)">
                        <arrow-right-circle-icon class="fea icon-sm" style="cursor: pointer;"></arrow-right-circle-icon>
                      </a>
                    </td>
                  </tr>
                  <tr v-if="opened.includes(x.bunch_id)">
                    <td colspan="6">
                      <div style="max-height:20em; width: 100%; overflow-y:auto; overflow-x:auto;">
                        <MsgsUnderCut :messages="x.top_messages" :ners="x.relevant_ners" :stream="stream">
                        </MsgsUnderCut>
                      </div>
                    </td>
                  </tr>
                </template>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import store from "@/store/store";
import TagButton from './tagButton.vue';
import {
  DownloadIcon,
  ArrowRightCircleIcon,
  ArrowUpCircleIcon,
  ArrowDownCircleIcon,
  ArrowUpIcon,
  ArrowDownIcon,
  HelpCircleIcon,
  FilterIcon,
  GitMergeIcon,
  RefreshCwIcon,
  EyeOffIcon,
  DownloadCloudIcon
} from "vue-feather-icons";
import MsgsUnderCut from "./MsgsUnderCut.vue";
import MergePopupFeed from "./MergePopupFeed.vue";


export default {
  name: "topic-modelling-bunch-feed",
  components: {
    DownloadIcon,
    ArrowRightCircleIcon,
    ArrowUpCircleIcon,
    ArrowDownCircleIcon,
    ArrowUpIcon,
    ArrowDownIcon,
    HelpCircleIcon,
    FilterIcon,
    GitMergeIcon,
    RefreshCwIcon,
    EyeOffIcon,
    TagButton,
    MsgsUnderCut,
    MergePopupFeed,
    DownloadCloudIcon
  },
  data() {
    return {
      bunches_list: [],
      num_of_all_mesages: '',
      allCheck: false,
      num_of_good_mesages: '',
      num_of_bad_mesages: '',
      incTags: [],
      excTags: [],
      currentSelections: [],
      knownTagsAndId: [],
      discovered_bunch_list: [],
      start_time: '',
      last_message_time: '',
      currentSort: 'rank',
      currentSortDir: 'asc',
      clusterization_id: '',
      opened: [],
      loading: true,
      stream: "SberBA",
      popupVisible: false,
      filtersVisible: false,
      knownTags: [],
      inputTagId: -1,
      chartOptions: {
        chart: {
          type: 'pie',
          zoom: {
            enabled: false
          },
          avoidLabelOverlap: false,
          label: {
            show: false,
            position: 'center'
          },
        },
        colors: ['#0077FF', '#0088cc', '#ed812b', '#C13584', '#FF0000', '#1DA1F2', '#4267B2', '#595a00', '#029745', '#79867f'],
        dataLabels: {
          enabled: false
        },
        legend: {
          show: false
        },
        labels: ['VK', 'TG', 'OK', 'IG', 'YT', 'Twitter', 'FB', 'Dzen', 'Banki', 'Other',]
      },
      series:
        [4, 2, 1, 0, 0, 0, 9, 5, 9, 0],
    };
  },

  mounted() {
    this.collectTags();
    this.getData();
  },

  methods: {
    getHostname() {
      let basic_url = ''
      if (this.stream == "SberBA") {
        basic_url = basic_url + this.$hostname_ba
      } else {
        basic_url = basic_url + this.$hostname_ba_macro
      }
      return basic_url;
    },
    getData() {
      this.loading = true;
      let requestData = {
        include: this.incTags.map(x => x.value),
        exclude: this.excTags.map(x => x.value)
      };
      this.axios({
        method: "post",
        data: requestData,
        url: this.getHostname() + "/plot-modelling/get-bunch-feed",
      }).then((res) => {
        console.log(res.data);
        this.bunches_list = res.data.data;
        this.relevant_ners = res.data.relevant_ners;
        this.num_of_all_mesages = res.data.num_of_all_mesages;
        this.num_of_good_mesages = res.data.num_of_good_mesages;
        this.num_of_bad_mesages = this.num_of_all_mesages - this.num_of_good_mesages;
        this.start_time = res.data.start_time;
        this.last_message_time = res.data.last_message_time;
      });
    },

    finalizeMerge() {
      this.popupVisible = false//!this.popupVisible;
      this.bunches_list.forEach(function (part, index) { part.selected = null; });
      this.currentSelections = [];
    },
    clearFilters() {
      this.incTags = [];
      this.excTags = [];
      this.getData();
    },
    collectTags() {
      this.axios({
        method: "get",
        url: this.getHostname() + "/plot-modelling/collect-tags",
      }).then((res) => {
        // this.knownTagsAndId = res.data.data;
        // this.knownTags = this.knownTagsAndId.map(x => x.tag_name);
        this.knownTags = res.data.data.map(({ tag_name: text, tag_id: value }) => ({ text, value }));
        console.log('tags loaded');
      })
    },
    removeTagFromList(tag_id, mode) {
      if (mode == 'exclude') {
        this.excTags = this.excTags.filter(x => x.value != tag_id);

      } else if (mode == 'include') {
        this.incTags = this.incTags.filter(x => x.value != tag_id);

      } else {
        console.log('wrong mode provided');
      };
      this.getData();
    },
    onSubmitTag() {
      // console.log('clicked submit');
    },
    includeClicked() {
      console.log('clicked include');
      let to_push = this.knownTags.filter(x => x.value == this.inputTagId)[0];
      if (typeof to_push !== 'undefined') {
        this.incTags.push(to_push);
      }
      this.inputTagId = -1;
      this.getData();
    },
    excludeClicked() {
      console.log('clicked exclude');
      let to_push = this.knownTags.filter(x => x.value == this.inputTagId)[0];
      if (typeof to_push !== 'undefined') {
        this.excTags.push(to_push);
      }
      this.inputTagId = -1;
      this.getData();
    },
    sort: function (s) {
      //if s == current sort, reverse
      if (s === this.currentSort) {
        this.currentSortDir = this.currentSortDir === 'asc' ? 'desc' : 'asc';
      }
      this.currentSort = s;
    },
    toggle(id) {
      const index = this.opened.indexOf(id);
      if (index > -1) {
        this.opened.splice(index, 1)
      } else {
        this.opened.push(id)
      }
    },
    format_timedelta(mseconds) {
      if (mseconds < (10 ** 3) * 3600 * 2) {
        let val = Math.floor(((mseconds / 10 ** 3) % 3600) / 60);
        return val >= 5 ? `${val} минут` : `${val} минуты`;
      } else if (mseconds > (10 ** 3) * 3600 * 24 * 3) {
        let val = Math.floor((mseconds / 10 ** 3) / (3600 * 24));
        return val >= 5 ? `${val} дней` : `${val} дня`;
      } else {
        let val = Math.floor((mseconds / 10 ** 3) / 3600);
        return val >= 5 ? `${val} часов` : `${val} часа`;
      };
    },
    getExcel() {
      let requestData = {
        include: this.incTags.map(x => x.value),
        exclude: this.excTags.map(x => x.value)
      };


      this.$vs.loading({
        container: '#div-with-loading',
        scale: '0.45',
      });


      this.axios({
        method: 'post',
        url: this.getHostname() + "/plot-modelling/get-bunch-feed-data-file",
        data: requestData,
        responseType: "blob",
      }).then((response) => {
        this.$vs.loading.close("#div-with-loading > .con-vs-loading");
        const blob = new Blob(
          [response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        const aEle = document.createElement('a');     // Create a label
        const href = window.URL.createObjectURL(blob);       // Create downloaded link
        aEle.href = href;
        let datetime = new Date().toLocaleTimeString() + '_' + new Date().toLocaleDateString().replace(/\./g, '_');
        aEle.download = 'report_' + datetime + '.xlsx';  // File name after download
        document.body.appendChild(aEle);
        aEle.click();     // Click to download
        document.body.removeChild(aEle); // Download complete remove element
        window.URL.revokeObjectURL(href); // Release blob object

      })
    },
    getHistory(bunch_id) {
      let url = '' + 'bunchHistory/' + bunch_id + '/' + this.stream;
      window.open(url, '_blank');
      // this.$router.push(url);
    },
    onChange(value) {
      this.stream = value ? "SberBA" : "MacroBA";
      this.collectTags();
      this.getData();
    },
    mergeClicked() {
      if (this.currentSelections.length == 0) {
        alert('Выберите сюжеты для объединения')
      } else {
        this.popupVisible = !this.popupVisible;
        this.$refs.popup.mergeClicked();
      };
    },
  },
  computed: {
    sorted_bunches_list: function () {
      this.loading = true;
      let a = this.bunches_list.sort((a, b) => {
        let modifier = 1;
        if (this.currentSortDir === 'desc') modifier = -1;
        if (a[this.currentSort] < b[this.currentSort]) return -1 * modifier;
        if (a[this.currentSort] > b[this.currentSort]) return 1 * modifier;
        return 0;
      });
      this.loading = false;
      return a;
    },
    allSelected() {
      return this.bunches_list.every(itm => itm.selected);
    },
    filtered_bunches_list() {
      return this.bunches_list.filter((r) => this.currentSelections.includes(r.bunch_id));
    }
  },


  watch: {
    bunches_list: {
      handler() {
        this.currentSelections = this.bunches_list
          .filter(itm => itm.selected)
          .map(itm => itm.bunch_id);
      },
      deep: true
    }
  }


};
</script>

<style scoped>
.opened {
  background-color: #253649;
}

.tooltip {
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted black;
  /* If you want dots under the hoverable text */
}

/* Tooltip text */
.tooltip .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: #fff;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;

  /* Position the tooltip text - see examples below! */
  position: absolute;
  z-index: 1;
}

/* Show the tooltip text when you mouse over the tooltip container */
.tooltip:hover .tooltiptext {
  visibility: visible;
}

/* .vs-table--header {
  background: rgb(40 61 85) !important;
} */

.vs-th {
  background: rgb(31 45 61);
}

.vs-table--tbody-table tr,
th {
  background: rgb(31 45 61);

}

.vs-con-table table {
  background: rgb(31 45 61);
}
</style>